<template>
  <div class="work flex">
    <div class="work_left">
      <ul class="tab flex marB20">
        <li
          v-for="(item,index) in tab"
          :key="index"
          :class="{'active':active == index}"
          @click="tabs(index)"
        >{{item}}</li>
      </ul>
      <!-- 投递成功 1 -->
      <ul
        v-if="active=='0'"
        class="list loads"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
      >
        <li class="box flex" v-for="item in workList" :key="item.id">
          <div class="left">
            <router-link
              :to="{path:'/job/jobDetail/',query:{id:item.jobObject.id}}"
              :title="item.jobName"
              target="_blank"
            >
              <h2>{{item.jobObject.jobName}}</h2>
            </router-link>
            <p class="money" v-if="item.jobObject.jobSalary">{{item.jobObject.jobSalary}}</p>
            <p class="mon_ey" v-else>暂无薪资信息</p>
            <div class="company">{{item.companyObject.companyName}}</div>
          </div>
          <div class="right">
            <div class="type">
              <span>投递成功</span>
            </div>
            <div class="time">{{item.createTime}}</div>
          </div>
        </li>
      </ul>
      <!-- 被查看 2 -->
      <ul
        class="list loads"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        v-show="active=='1'"
      >
        <li class="box flex" v-for="item in workList" :key="item.id">
          <div class="left">
            <router-link
              :to="{path:'/job/jobDetail/',query:{id:item.jobObject.id}}"
              :title="item.jobName"
              target="_blank"
            >
              <h2>{{item.jobObject.jobName}}</h2>
            </router-link>
            <p class="money" v-if="item.jobObject.jobSalary">{{item.jobObject.jobSalary}}</p>
            <p class="mon_ey" v-else>暂无薪资信息</p>
            <div class="company">{{item.companyObject.companyName}}</div>
          </div>
          <div class="right">
            <div class="type">
              <span>被查看</span>
            </div>
            <div class="time">{{item.createTime}}</div>
          </div>
        </li>
      </ul>
      <!-- 有意向 3 -->
      <ul
        class="list loads"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        v-show="active=='2'"
      >
        <li class="box flex" v-for="item in workList" :key="item.id">
          <div class="left">
            <router-link
              :to="{path:'/job/jobDetail/',query:{id:item.jobObject.id}}"
              :title="item.jobName"
              target="_blank"
            >
              <h2>{{item.jobObject.jobName}}</h2>
            </router-link>
            <p class="money" v-if="item.jobObject.jobSalary">{{item.jobObject.jobSalary}}</p>
            <p class="mon_ey" v-else>暂无薪资信息</p>
            <div class="company">{{item.companyObject.companyName}}</div>
          </div>
          <div class="right">
            <div class="type">
              <span>有意向</span>
            </div>
            <div class="time">{{item.createTime}}</div>
          </div>
        </li>
      </ul>
      <!-- 邀请面试 4 5 6-->
      <ul
        class="list loads"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        v-show="active=='3'"
      >
        <li class="box flex" v-for="(item,index) in workList" :key="item.id">
          <div class="left">
            <router-link
              :to="{path:'/job/jobDetail/',query:{id:item.jobObject.id}}"
              :title="item.jobName"
              target="_blank"
            >
              <h2>{{item.jobObject.jobName}}</h2>
            </router-link>
            <p class="money" v-if="item.jobObject.jobSalary">{{item.jobObject.jobSalary}}</p>
            <p class="mon_ey" v-else>暂无薪资信息</p>
            <div
              class="company"
              v-if="item.companyObject.companyName"
            >{{item.companyObject.companyName}}</div>
            <div class="company" v-else>wu</div>
          </div>
          <div class="right">
            <div class="type">
              <el-button
                type="primary"
                size="mini"
                @click="handleClick(index)"
                v-if="item.applyStatus=='4'"
              >查看详情</el-button>
              <span v-else-if="item.applyStatus=='5'">已接受</span>
              <span v-else class="styl">已拒绝</span>
            </div>
            <div class="time">{{item.createTime}}</div>
          </div>
        </li>
      </ul>

      <el-dialog
        :title="title"
        :visible.sync="isShow"
        width="500px"
        :before-close="handleClose"
        v-if="isShow"
      >
        <el-form ref="form" label-width="120px">
          <el-form-item label="联系人：" prop="linkMan">{{interviewInfo.linkMan}}</el-form-item>
          <el-form-item label="联系电话：" prop="linkTel">{{interviewInfo.linkTel}}</el-form-item>
          <el-form-item
            label="面试时间："
            prop="interviewTime"
          >{{interviewInfo.provinceid}}{{interviewInfo.cityid}}{{interviewInfo.threeCityid}}</el-form-item>
          <el-form-item label="面试地点：" prop="provinceid">{{interviewInfo.address}}</el-form-item>
          <el-form-item label="详细地址：" prop="address">{{interviewInfo.address}}</el-form-item>
          <el-form-item label="面试备注：" class="text_L">
            <p v-html="interviewInfo.applyMessage"></p>
          </el-form-item>
          <div class="buttons">
            <el-button
              type="primary"
              class="marR20"
              size="mini"
              @click="agrees(interviewInfo.id,5)"
            >接受</el-button>
            <el-button
              type="info"
              size="mini"
              style="margin-left:0px"
              @click="resufes(interviewInfo.id,6)"
            >拒绝</el-button>
          </div>
        </el-form>
      </el-dialog>
      <!-- 不合适 7-->
      <ul
        class="list loads"
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        v-show="active=='4'"
      >
        <li class="box flex" v-for="item in workList" :key="item.id">
          <div class="left">
            <router-link
              :to="{path:'/job/jobDetail/',query:{id:item.jobObject.id}}"
              :title="item.jobName"
              target="_blank"
            >
              <h2>{{item.jobObject.jobName}}</h2>
            </router-link>
            <p class="money" v-if="item.jobObject.jobSalary">{{item.jobObject.jobSalary}}</p>
            <p class="mon_ey" v-else>暂无薪资信息</p>
            <div class="company">{{item.companyObject.companyName}}</div>
          </div>
          <div class="right">
            <div class="type">
              <span>不合适</span>
            </div>
            <div class="time">{{item.createTime}}</div>
          </div>
        </li>
      </ul>

      <!-- 分页 -->
      <div class="pages">
        <el-pagination
          background
          class="work_pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="work.pageNum"
          :page-size="work.pageSize"
          layout="prev, pager, next, total, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <!-- 右边 优选推荐 -->
    <div class="work_right">
      <div class="top flex">
        <img src="~/static/public/apps.png" alt />
        <div class="text">下载中国残联app，安心面试，顺利求职！</div>
      </div>
      <div class="bottom">
        <div class="title">
          <h1 class="relative">
            为您优选
            <span @click.stop="refresh">换一批</span>
          </h1>
        </div>
        <ul class="marL10">
          <li v-for="item in recomdList" :key="item.index">
            <router-link :to="'/job/jobDetail/?id='+item.id" target="_blank">
              <h4 class="line_clamp1">{{item.jobName}}</h4>
            </router-link>

            <p class="mon_ey" v-if="item.jobSalary">{{item.jobSalary}}</p>
            <p class="mon_ey" v-else>面议</p>
            <div class="company line_clamp1">{{item.companyInfo.companyName}}</div>
            <el-divider></el-divider>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "work",

  data() {
    return {
      title: "企业邀请信息",
      active: "0",
      isShow: false,
      loading: false,
      tab: ["视频面试预约", "同意视频面试", "有签约意向", "无签约意向"],
      total: 0,
      workList: [],
      recomdList: [],
      interviewInfo: {},
      pagination: {
        pre: "上一页",
        next: "下一页",
      },
      work: {
        pageNum: 1,
        pageSize: 10,
        applyStatus: "1",
      },
      params: {
        pageNum: 1,
        pageSize: 8,
      },
    };
  },
  methods: {
    tabs(i) {
      this.active = i;
      console.log("我的投递", i);
      if (this.active == "0") {
        this.work.applyStatus = 1;
      } else if (this.active == "1") {
        this.work.applyStatus = 2;
      } else if (this.active == "2") {
        this.work.applyStatus = 3;
      } else if (this.active == "3") {
        this.work.applyStatus = "4,5,6";
      } else {
        this.work.applyStatus = 7;
      }
      this.getWorkList();
    },
    // 请求我投递的接口
    async getWorkList() {
      let that = this;
      try {
        this.loading = true;
        let res = await that.$api.getDisableJobApply(that.work);
        if (res.data.success) {
          this.workList = res.data.data.records;
          this.total = res.data.data.total;
          this.loading = false;
        } else {
          this.$message.error("错误了");
        }
      } catch (error) {
        console.log(err);
      }
    },
    // 职位优选列表
    async queryAboutApplyJob() {
      // this.loading = true;
      try {
        let res = await this.$api.queryAboutApplyJob(this.params);
        console.log("优选职位", res);
        if (res.data.success) {
          this.recomdList = res.data.data.records;
          // this.loading = false;
        } else {
          this.$message.error(res.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    refresh() {
      this.queryAboutApplyJob();
      this.$message.success("成功！");
    },
    handleClose() {
      this.isShow = false;
    },
    agrees(id, applyStatus) {
      let params = {
        id: id,
        applyStatus: applyStatus,
      };
      this.$confirm("若您同意当前企业对那您的邀请, 请点击确定?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$api.accexpOrjujue(params);
          if (res.data.success) {
            this.$message({
              type: "success",
              message: res.data.msg,
            });
            this.isShow = false;
            this.tabs(3);
          } else {
            this.$message.error(res.data.msg);
            this.isShow = false;
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
          this.isShow = false;
        });
    },
    resufes(id, applyStatus) {
      let params = {
        id: id,
        applyStatus: applyStatus,
      };
      this.$confirm("请确定你是否拒绝当前企业对那您的邀请, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$api.accexpOrjujue(params);
          if (res.data.success) {
            this.$message({
              type: "success",
              message: "已拒绝！",
            });
            this.isShow = false;
            this.tabs(3);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
          this.isShow = false;
        });
    },

    //分页
    handleCurrentChange(val) {
      this.pageNum = val;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleClick(index) {
      this.interviewInfo = this.workList[index].interviewInfo;
      this.interviewInfo.id = this.workList[index].id;
      this.isShow = true;
    },
  },
  mounted() {
    this.tabs(0);
    this.queryAboutApplyJob();
  },
};
</script>

<style scoped lang="less">
@import "~assets/css/center/center.less";
.mon_ey {
  color: #f65150;
  font-size: 14px;
  font-weight: bold;
  padding-right: 10px;
  margin: 6px 0;
}
.buttons {
  display: flex;
  width: 60%;
  margin: auto;
  justify-content: space-around;
}
.styl {
  color: red;
}
/deep/ .el-loading-spinner {
  top: 35%;
}
/deep/ .el-divider--horizontal {
  margin: 10px 0;
}
</style>
